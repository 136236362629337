<template>
  <div class="view">
    <v-toolbar class="my-bar">
<!--      <v-btn v-if="rightFilter('w')" icon :loading="loading" @click="save">
        <v-icon>save</v-icon>
      </v-btn>-->
      <SaveButton
      :handler="save"
      >

      </SaveButton>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      item-key="access"
      hide-default-footer
      :loading="loading"
      loading-text="Загрузка... Пожалуйста подождите"
      fixed-header
    >
      <template v-slot:item.read="{ item }">
        <v-simple-checkbox
          v-if="item.pattern.includes('r')"
          v-model="item.read"
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.write="{ item }">
        <v-simple-checkbox
          v-if="item.pattern.includes('w')"
          v-model="item.write"
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-simple-checkbox
          v-if="item.pattern.includes('d')"
          v-model="item.delete"
        ></v-simple-checkbox>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ctx from "@/ctx";
import fetch from "../../utils/fetch";
import routes from "../../router/routes";
import vm2js from "../../utils/vm2js";
import js2vm from "../../utils/js2vm";
import rightFilter from "../../filters/right.filter";
import SaveButton from "@/components/ButtonFacade";

let Item = function(d) {
  let self = this;

  js2vm(self, d);
  let route = routes.get().find(i => {
    return i.meta && i.meta.access === d.access;
  });

  self.label = route ? route.meta.label : "n/a";
  if(d.access === 'DICTIONARIES') self.label = "Справочники"
  self.read = d?.rights.includes("r") ? true : false;
  self.write = d?.rights.includes("w") ? true : false;
  self.delete = d?.rights.includes("d") ? true : false;
};

export default {
  components: {SaveButton},
  props: {
    vm: Object
  },
  data: () => ({
    ctx,
    rightFilter,
    items: [],
    loading: false,
    headers: [
      {
        text: "Доступ",
        align: "start",
        value: "label"
      },
      { text: "Просмотр", sortable: false, value: "read" },
      { text: "Изменение", sortable: false, value: "write" },
      { text: "Удаление", sortable: false, value: "delete" }
    ]
  }),
  watch: {
    "vm.item": function(val) {
      if (val) {
        this.fetchItems();
      }
    }
  },
  methods: {
    async save() {
      this.loading = true
      let arr = vm2js(this.items);
      arr.forEach(i => {
        i.rights = i.read ? "r" : "-";
        i.rights = i.write ? i.rights.concat("w") : i.rights.concat("-");
        i.rights = i.delete ? i.rights.concat("d") : i.rights.concat("-");
      });
      await fetch.post("/api/access/save", arr);
      await this.fetchItems();
      this.loading = false
    },
    async fetchItems() {
      this.items = [];
      if (!this.vm.item.id) return;
      this.loading = true;
      let res = await fetch.get(
        "/api/access/get-list?userId=" + this.vm.item.id
      );
      if (res) res.forEach(r => this.items.push(new Item(r)));
      this.loading = false;
    }
  },
  mounted() {
    this.vm.access = this;
    this.fetchItems();
  }
};
</script>
